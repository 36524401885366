const Service = {
    state: {
        technologyParkItemId: null,
        modelName: '',
        description: '',
    },
    getters: {
        getTechnologyParkItemId: state => state.technologyParkItemId,
        getModelName: state => state.modelName,
        getDescription: state => state.description,
    },
    mutations: {
        setTechnologyParkItemId(state, item) {
            state.technologyParkItemId = item;
        },
        setModelName(state, text) {
            state.modelName = text;
        },
        setDescription(state, text) {
            state.description = text;
        },
    },
    actions: {
        selectTechnologyParkItemId({commit}, item) {
            commit('setTechnologyParkItemId', item);
        },
        updateModelName({commit}, text) {
            commit('setModelName', text);
        },
        updateDescription({commit}, text) {
            commit('setDescription', text);
        },
    },
};

export {
    Service
};
