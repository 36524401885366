<div>
    <PageHeader>
        <div class="row c">
            <Breadcrumbs :items="breadcrumbs" />
        </div>
    </PageHeader>

    <PageTitle
        title="Сервис"
        class="mb-2"
    />

    <Filter
        :on-change="findResults"
        :on-new-partner="createPartner"
        class="p-0"
    />

    <questionnaire />

    <PartnerGrid
        :partner-feed="partnerFeed"
        :contact-positions="contactPositions"
        :changePartner="changePartner"
        :action-select-partner="actionSelectPartner"
    />

    <teleport
        v-if="showModal"
        to="body"
    >
        <Modal
            v-if="showModal"
            @onOk="closeModal"
            @onClose="closeModal"
        >
            <template #body>
                <PartnerCart
                    v-model:id="changedPartner"
                    v-model:global-mode="mode"
                    :on-cancel="cancelPartnerEdit"
                    :actions="actions"
                    @close-modal="closeModal"
                />
            </template>
        </Modal>
    </teleport>
</div>
