export const CONTACT_TYPE_WORK_PHONE = 1;
export const CONTACT_TYPE_HOME_PHONE = 2;
export const CONTACT_TYPE_EMAIL = 3;
export const CONTACT_TYPES = [
    CONTACT_TYPE_WORK_PHONE,
    CONTACT_TYPE_HOME_PHONE,
    CONTACT_TYPE_EMAIL,
];

export const SERVICE_TYPES = [
    {value: true, label: 'Если гарантийный'},
    {value: false, label: 'Если платный'},
];
