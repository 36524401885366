<script>
import FormButton from '@c/Form/Button';
import {SERVICE_TYPES} from '../config';

export default {
    name: 'Questionnaire',
    components: {
        FormButton
    },
    data() {
        return {
            collapse: true,
            selectedServiceType: true,
            serviceTypes: SERVICE_TYPES,
        };
    },
};
</script>

<template>
    <div class="row c no-gap">
        <div class="col-9">
            <div class="d-flex flex-nowrap bg-light-green position-relative p-1">
                <form-button
                    type="a"
                    class="btn-collapse"
                    @click="collapse = !collapse"
                >
                    <template #center>
                        <span>{{ collapse ? 'Развернуть' : 'Свернуть' }}</span>
                    </template>
                    <template #right>
                        <i v-if="collapse" class="icon chevron chevron_rotate-down" />
                        <i v-else class="icon chevron chevron_rotate-top" />
                    </template>
                </form-button>

                <div><i class="icon warning mr-1" /></div>

                <div>
                    <p class="font-weight-semibold">Скрипт первичного обращения:</p>
                    <ol
                        class="questionnaire-list"
                        :class="{'collapse' : collapse}"
                    >
                        <li>
                            <p>
                                <b>Прием звонка</b><br>
                                <small>выяснить кто звонит</small>
                            </p>

                            <div v-if="!collapse">
                                <p>
                                    <b>Сотрудник ЛБР:</b><br>
                                    Здравствуйте. Меня зовут [имя]. Подскажите, пожалуйста, как я могу к вам обращаться?<br>
                                </p>
                                <p><i>(Дождаться ответа)</i></p>
                                <p>
                                    <b>Сотрудник ЛБР:</b><br>
                                    Спасибо! А какую компанию вы представляете?<br>
                                </p>
                                <p><i>(Дождаться ответа и уточнить, если необходимо)</i></p>
                                <p>
                                    <b>Сотрудник ЛБР:</b><br>
                                    Очень приятно! Чем можем вам помочь?
                                </p>
                            </div>
                        </li>
                        <li>
                            <p>
                                <b>Выяснить типа сервиса</b><br>
                                <small>(гарантийный/платный)</small>
                            </p>

                            <div v-if="!collapse">
                                <p>
                                    <b>Сотрудник ЛБР:</b><br>
                                    Понял(а) вас. Уточните, пожалуйста, вам требуется гарантийный ремонт или платный сервис?
                                </p>
                                <p>
                                    <i>(Если клиент не знает, помочь разобраться, уточняя информацию о гарантии на технику.)</i>
                                </p>

                                <div class="mb-2">
                                    <form-button
                                        v-for="type in serviceTypes"
                                        :key="`service-type-btn-${type.value}`"
                                        :class="[selectedServiceType === type.value ? 'btn-gray' : 'no-bg']"
                                        class="mr-1"
                                        @click="selectedServiceType = type.value"
                                    >
                                        <template #center>
                                            {{ type.label }}
                                        </template>
                                    </form-button>
                                </div>

                                <div v-if="selectedServiceType">
                                    <p>
                                        <b>Сотрудник ЛБР:</b><br>
                                        Понял(а). Для того чтобы наша служба технической поддержки могла принять ваше обращение, нам потребуется собрать первичные данные. Это требование завода-изготовителя, и, к сожалению, без них мы не сможем начать обработку запроса.
                                    </p>
                                    <p>Необходимо сделать пару фотографий:</p>
                                    <ul>
                                        <li>Фото неисправности;</li>
                                        <li>Фото шильды техники (таблички с заводскими данными) и прочее;</li>
                                    </ul>
                                    <p>Все это можно загрузить в удобное для вас время в специальной форме на нашем сайте, это не займёт много времени.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>
                                <b>Уточнить контактное лицо</b><br>
                                <small>и предпочтительный вид связи</small>
                            </p>

                            <div v-if="!collapse">
                                <p>
                                    <b>Сотрудник ЛБР:</b><br>
                                    Также уточните, пожалуйста, кто из ваших сотрудников будет находиться рядом с техникой, чтобы, если понадобится, мы могли связаться для уточнения деталей, например, сделать дополнительные фото или обсудить неисправность.
                                </p>
                                <p>Как зовут этого сотрудника, и куда ему удобнее отправить ссылку на форму — на email или в мессенджер?</p>
                                <p>
                                    <i>(Уточнить контактный телефон, email или предпочитаемый мессенджер, например, WhatsApp, если требуется)</i>
                                </p>
                            </div>
                        </li>
                        <li>
                            <p>
                                <b>Детали неисправности</b><br>
                                <small>и первичное описание</small>
                            </p>

                            <div v-if="!collapse">
                                <p>
                                    <b>Сотрудник ЛБР:</b><br>
                                    Теперь уточним немного про саму технику.
                                </p>
                                <ul>
                                    <li>Какое оборудование требует сервиса (серийный номер, наименование)?</li>
                                    <li>В чём именно заключается неисправность (или какой сервис нужен)?</li>
                                </ul>
                                <p>
                                    <i>(Дождаться подробного описания и записать все детали.)</i>
                                </p>
                            </div>
                        </li>
                        <li>
                            <p><b>Завершение разговора и объяснение следующих шагов</b></p>

                            <div v-if="!collapse">
                                <p>
                                    <b>Сотрудник ЛБР:</b><br>
                                    Спасибо за предоставленную информацию! Мы отправим ссылку на форму указанному вами сотруднику.
                                </p>
                                <p>
                                    <span class="font-weight-bold">Важно:</span> как только форма будет заполнена, наш специалист незамедлительно возьмёт заявку в работу. Это требование завода-изготовителя, чтобы мы могли оперативно и качественно вам помочь.
                                </p>
                                <p>
                                    После получения данных в течение 4 часов вам перезвонит сотрудник службы техподдержки, чтобы обсудить дальнейшие действия.
                                </p>
                                <p>
                                    Если у вас появятся дополнительные вопросы, вы всегда можете обратиться к нам по этому номеру. Спасибо за звонок, хорошего дня!
                                </p>
                                <p>
                                    <i>(Завершить разговор.)</i>
                                </p>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<style src="../styles/questionnaire.scss" scoped lang="scss"></style>
