<teleport to="body">
    <Modal
        @on-ok="updateDisplayCompareProductsPopup(false)"
        @on-close="updateDisplayCompareProductsPopup(false)"
        :buttons="[]"
        :size="'big'"
    >
        <template v-slot:header>
            <h2 class="d-inline-block mr-1">Сравнение товаров</h2>
            <slot name="header" />
            <competitor-popup
                v-if="displayCompetitorPopup && competitorProductsForCompare.length > 0"
                :lbr-product-id="productIdToShowCompetitors.toString()"
                :competitor-products="competitorProductsForCompare"
                :selected-competitor-products="selectedProductsForCompare"
                :fn-format-price="fnFormatPrice"
                @update:display-competitor-popup="value => displayCompetitorPopup = value"
                @update:competitorsToCompare="onAddCompetitorProductsToCompare"
            />
        </template>
        <template v-slot:body>
            <div class="compare-products-container">
                <table class="fixed-scrollbar-container" ref="fixedScrollbarContainer">
                    <tr v-for="line in feed">
                        <template v-for="column in line">
                            <td :colspan="column.options?.colspan ? column.options?.colspan : 1">
                                <component
                                    v-if="column.component"
                                    :is="column.component"
                                    :column="column"
                                    :handlers="handlers"
                                />
                                <column-default v-else
                                    :column="column"
                                />
                            </td>
                        </template>
                    </tr>
                </table>
            </div>
        </template>
    </Modal>
</teleport>

