<template src="./templates/modal-container.html"></template>

<script>

import Button from '@f/Button';
import '../../layouts/default/styles/modal.scss';
import {
    MODAL_BIG,
    MODAL_DEFAULT,
    MODAL_SIZES
} from './config';

export default {
    name: 'ModalContainer',
    components: {
        Button
    },
    props: {
        closeButton: {
            type: Boolean,
            default() {
                return true;
            }
        },
        buttons: {
            type: Array,
            default() {
                return [];
            }
        },
        templateButtons: {
            type: Object,
            default() {
                return {};
            }
        },
        size: {
            type: String,
            default: MODAL_DEFAULT,
            validator(val) {
                return MODAL_SIZES.includes(val);
            }
        },
    },
    emits: ['onClose', 'onOk'],
    data() {
        return {
            modalBig: MODAL_BIG,
        };
    },
    methods: {
        callEvent(action, e) {
            e.stopPropagation();
            this.$emit(action);
        }
    }
};
</script>

