<div class="stock-availability">
    <template v-if="item.competitor">
        <div class="competitor-container">
            <div class="competitor-container__label title">КОНКУРЕНТ</div>
            <div class="competitor-container__label name">
                {{item.competitor.name}}
            </div>
            <div class="competitor-container__label region">
                {{item.competitor.region}}
            </div>
        </div>
    </template>

    <template v-else>
        <div class="count"  v-if="item.full_count > 0">
            <div class="available text-green">В наличии</div>
            <div class="counter">{{item.count}} / {{item.full_count}} шт.</div>
        </div>
        <div class="fastest-delivery" v-else>Ближайшая поставка: {{item.fastestDelivery}}</div>
        <div v-if="column.data.hasCompetitorProducts" class="compare-competitor-button" @click="handlers.showCompetitorPopup(column.data.id)">Конкуренты</div>
    </template>
</div>
