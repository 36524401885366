<template src="./questionnaire-competitors.html"></template>
<script>

import Modal from '@c/Modal';
import FormCheckbox from '@f/Check';
import './questionnaire-competitors.scss';

export default {
    name: 'CompetitorPopup',
    components: {
        Modal,
        FormCheckbox,
    },
    props: {
        lbrProductId: {
            type: String,
            required: true,
        },
        competitorProducts: {
            type: Array,
            default() {
                return [];
            }
        },
        selectedCompetitorProducts: {
            type: Object,
            default() {
                return {};
            }
        },
        fnFormatPrice: {
            type: Function,
            default() {
                return () => {};
            }
        },
    },
    emits: ['update:displayCompetitorPopup', 'update:competitorsToCompare'],
    data() {
        return {
            selected: null,
        };
    },
    mounted() {
        this.selected = {...this.selectedCompetitorProducts};
    },
    methods: {
        updateDisplayCompetitorPopup(display) {
            this.$emit('update:displayCompetitorPopup', display);
        },
        addToCompare() {
            this.$emit('update:competitorsToCompare', this.selected);
            this.updateDisplayCompetitorPopup(false);
        },
        getPriceLabel(priceData) {
            const priceMin = this.fnFormatPrice(priceData.min, priceData.currency);

            if (priceData.min !== priceData.max) {
                const priceMax = this.fnFormatPrice(priceData.max, priceData.currency);
                return `${priceMin} - ${priceMax}`;
            }

            return priceMin;
        },
        toggleSelected(competitorProductId) {
            if (!this.selected[this.lbrProductId]) {
                this.selected[this.lbrProductId] = [
                    competitorProductId,
                ];
                return;
            }

            if (this.selected[this.lbrProductId].includes(competitorProductId)) {
                this.selected[this.lbrProductId] = this.selected[this.lbrProductId].filter(p => p !== competitorProductId);
            } else {
                this.selected[this.lbrProductId].push(competitorProductId);
            }
        },
    },
};
</script>
