<template src="../templates/partner-grid.html"></template>

<script>
import PartnerGrid from '@page/PartnerList/components/partner-grid';
import FormCheckbox from '@c/Form/Check';
import ContactInfo from './contact-info';

export default {
    name: 'PartnerGrid',
    components: {
        ContactInfo,
        FormCheckbox
    },
    extends: PartnerGrid
};
</script>

<style scoped>

</style>
