<div v-if="contactDetails.length > 0">
    <div
        class="d-flex align-items-center justify-content-between pointer"
        @click="collapse = !collapse"
    >
        {{ contactLabel }}
        <i
            :class="{'down' : !collapse}"
            class="icon arrow"
        ></i>
    </div>

    <div
        v-if="!collapse"
        class="mt-1 mb-2 ml-3"
    >
        <div
            v-if="emails.length > 0"
            class="d-flex flex-nowrap justify-content-between mb-2"
        >
            <div>
                <div class="mb-1">Выберите email:</div>
                <div
                    v-for="email in emails"
                    :key="`email-contact-${email.id}`"
                    class="mb-1"
                >
                    <FormRadio
                        :label="`${email.data}`"
                        :attributes="{
                            name: `select-email-${contact.id}`,
                        }"
                        :value="email.id"
                        v-model="selectedDestinationEmailId"
                    />
                </div>
            </div>
            <div>
                <FormCheckbox
                    label="Отправить запрос"
                    class="mb-1"
                    :attributes="{
                        name: `select-send-email-${contact.id}`
                    }"
                    v-model="sendDestinationEmail"
                />
                <div>на EMAIL</div>
            </div>
        </div>

        <div
            v-if="phones.length > 0"
            class="d-flex flex-nowrap justify-content-between mb-2"
        >
            <div>
                <div class="mb-1">Выберите телефон:</div>
                <div
                    v-for="phone in phones"
                    :key="`phone-contact-${phone.id}`"
                    class="mb-1"
                >
                    <FormRadio
                        :label="`${phone.data}`"
                        :attributes="{
                            name: `select-phone-${contact.id}`,
                        }"
                        :value="phone.id"
                        v-model="selectedDestinationPhoneId"
                    />
                </div>
            </div>
            <div>
                <FormCheckbox
                    label="Отправить запрос"
                    class="mb-1"
                    :attributes="{
                        name: `select-send-phone-${contact.id}`
                    }"
                    v-model="sendDestinationPhone"
                />
                <div>на WHATSAPP</div>
            </div>
        </div>

        <div class="mb-2">
            <p class="mb-1">Тип обращения:</p>
            <FormRadio
                v-for="type in serviceTypes"
                :key="`service-type-${type.value}`"
                :label="`${type.label}`"
                :attributes="{
                    name: `select-service-type-${contact.id}`,
                }"
                :value="type.value.toString()"
                v-model="hasWarrantyCoverage"
            />
        </div>
        <div class="d-flex justify-content-between align-items-end mb-2">
            <div>
                <p
                    v-if="getModelName"
                    class="text-primary mb-1"
                >
                    {{ getModelName }}
                </p>
                <form-button
                    v-if="getTechnologyParkItemId"
                    type="a"
                    label="Изменить"
                    class="action"
                    @click="changePartner(contact.partner_id)"
                />
            </div>
            <form-button
                v-if="!getTechnologyParkItemId"
                type="a"
                label="Выбрать технику"
                class="action"
                @click="changePartner(contact.partner_id)"
            />
            <form-button
                v-else
                type="a"
                label="Отправить"
                class="action"
                @click="showModal = true"
            />
        </div>
    </div>

    <teleport to="body">
        <Modal
            v-if="showModal"
            :close-button="true"
            :buttons="[]"
            @onClose="closeModal"
            @onOk="submitForm"
        >
            <template #header>
                <h2 class="text-black">
                    {{ success ? 'Сообщение отправлено' : 'Укажите обратившееся лицо' }}
                </h2>
            </template>

            <template #body>
                <div
                    v-if="!success"
                    class="px-3 pb-3"
                >
                    <div v-for="contact in filteredContacts">
                        <div class="d-flex align-items-center justify-content-between my-1">
                            <FormRadio
                                :label="`${contact.name} (${contact.position})`"
                                :attributes="{
                                    name: `select-initiator-contact-${contact.id}`,
                                }"
                                :value="contact.id"
                                v-model="selectedInitiatorContactId"
                            />
                        </div>

                        <div
                            v-if="contact.id === selectedInitiatorContactId"
                            class="mt-1 mb-2 ml-3"
                        >
                            <div
                                v-if="contact.emails.length > 0"
                                class="d-flex flex-nowrap justify-content-between mb-2"
                            >
                                <div>
                                    <div class="mb-1">Выберите email:</div>
                                    <div
                                        v-for="email in contact.emails"
                                        :key="`initiator-email-contact-${email.id}`"
                                        class="mb-1"
                                    >
                                        <FormRadio
                                            :label="`${email.data}`"
                                            :attributes="{
                                                name: `select-initiator-email-${email.id}`,
                                            }"
                                            :value="email.id"
                                            v-model="selectedInitiatorEmailId"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="contact.phones.length > 0"
                                class="d-flex flex-nowrap justify-content-between mb-2"
                            >
                                <div>
                                    <div class="mb-1">Выберите телефон:</div>
                                    <div
                                        v-for="phone in contact.phones"
                                        :key="`initiator-phone-contact-${phone.id}`"
                                        class="mb-1"
                                    >
                                        <FormRadio
                                            :label="`${phone.data}`"
                                            :attributes="{
                                                name: `select-initiator-phone-${phone.id}`,
                                            }"
                                            :value="phone.id"
                                            v-model="selectedInitiatorPhoneId"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col justify-content-center">
                            <form-button
                                class="btn btn-gray mr-1"
                                label="Отмена"
                                @click.stop.prevent="closeModal"
                            />
                            <form-button
                                class="btn btn-green"
                                label="Применить"
                                :preloader="process"
                                :disabled="process"
                                @click.stop.prevent="submitForm"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </teleport>
</div>
