import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import {Catalogs} from '@store/catalogs';
import {Preloaders} from '@store/preloaders';
import {Cyborg} from '@store/cyborg';
import {Service} from '@store/service';
import {User} from './user';


const store = Vuex.createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        User,
        Service,
        Catalogs,
        preloaders: Preloaders,
        Preloaders,
        Cyborg,
    },
    getters: {
        branches: state => state.Catalogs.branches,
        preloaders: state => state?.preloaders || {},
        cyborgId: state => state.Cyborg.id
    }
});

export default store;


