import api from '../tools/api';

export const save1CVO = (
    params,
    successHandler = () => {},
    errorHandler = () => {}
) => api.request(
    'service',
    'save-1c-vo',
    params,
    {},
    {
        resolve: successHandler,
        reject: errorHandler,
    }
);
