<template src="./templates/index.html"></template>

<script>
import PageHeader from '@c/Header';
import PageTitle from '@c/PageTitle';
import Breadcrumbs from '@c/Breadcrumbs';
import Modal from '@c/Modal';
import PartnerList from '@page/PartnerList';
import eventBus from '@/entities/eventBus';
import Questionnaire from './components/questionnaire';
import PartnerGrid from './components/partner-grid';

export default {
    name: 'Service',
    components: {
        Questionnaire,
        PartnerGrid,
        Breadcrumbs,
        PageTitle,
        PageHeader,
        Modal,
    },
    extends: PartnerList,
    data() {
        return {
            breadcrumbs: [
                {title: 'План переговоров'},
                {title: 'Сервис'},
            ],
        };
    },
    beforeMount() {
        eventBus.$on('close-modal', this.closeModal);
    },
    beforeUnmount() {
        eventBus.$off('close-modal');
    },
};
</script>

<style scoped>

</style>
