<template src="../templates/contact-info.html"></template>

<script>
import {mapGetters} from 'vuex';
import FormRadio from '@c/Form/Radio';
import FormCheckbox from '@c/Form/Check';
import FormButton from '@c/Form/Button';
import Modal from '@c/Modal';
import {save1CVO} from '@api/service';
import {
    CONTACT_TYPE_EMAIL,
    CONTACT_TYPE_HOME_PHONE,
    CONTACT_TYPE_WORK_PHONE,
    CONTACT_TYPES,
    SERVICE_TYPES
} from '../config';

export default {
    name: 'ContactInfo',
    components: {
        FormCheckbox,
        FormButton,
        FormRadio,
        Modal,
    },
    props: {
        contact: {
            type: Object,
            default: Object.prototype
        },
        position: {
            type: String,
            default: ''
        },
        contactTypes: {
            type: Object,
            default: Object.prototype
        },
        // Выбор партнера для карточки контрагента
        changePartner: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        contacts: {
            type: Array,
            default: Array.prototype
        }
    },
    data() {
        return {
            success: false,
            process: false,
            collapse: true,
            showModal: false,
            serviceTypes: SERVICE_TYPES,
            selectedDestinationPhoneId: null,
            selectedDestinationEmailId: null,
            sendDestinationPhone: false,
            sendDestinationEmail: false,
            hasWarrantyCoverage: null,
            selectedInitiatorContactId: null,
            selectedInitiatorPhoneId: null,
            selectedInitiatorEmailId: null,
        };
    },
    computed: {
        ...mapGetters([
            'getTechnologyParkItemId',
            'getModelName',
            'getDescription',
            'userExternalId',
        ]),
        contactDetails() {
            if (!this.contact?.contactDetails) {
                return [];
            }

            return this.contact.contactDetails.filter(item => {
                if (!CONTACT_TYPES.includes(parseInt(item.contact_data_type_id, 10))) {
                    return false;
                }
                return !(parseInt(item.disabled, 10) || parseInt(item.removed, 10));
            });
        },
        phones() {
            return this.contactDetails.filter(item => [
                CONTACT_TYPE_WORK_PHONE,
                CONTACT_TYPE_HOME_PHONE
            ].includes(parseInt(item.contact_data_type_id, 10)));
        },
        emails() {
            return this.contactDetails.filter(item => parseInt(item.contact_data_type_id, 10) === CONTACT_TYPE_EMAIL);
        },
        contactLabel() {
            let output = this.contact.name;
            if (this.position) {
                output += `( ${this.position} )`;
            }
            return output;
        },
        filteredContacts() {
            const contacts = [];

            // eslint-disable-next-line no-restricted-syntax,guard-for-in
            for (const key in this.contacts) {
                const {
                    id,
                    name,
                    Ref_Key,
                    position,
                    contactDetails
                } = this.contacts[key];

                const details = contactDetails.filter(item => {
                    if (!CONTACT_TYPES.includes(parseInt(item.contact_data_type_id, 10))) {
                        return false;
                    }
                    return !(parseInt(item.disabled, 10) || parseInt(item.removed, 10));
                });

                if (!details.length) {
                    // eslint-disable-next-line no-continue
                    continue;
                }

                const phones = details.filter(item => [
                    CONTACT_TYPE_WORK_PHONE,
                    CONTACT_TYPE_HOME_PHONE
                ].includes(parseInt(item.contact_data_type_id, 10)));

                const emails = details.filter(item => parseInt(item.contact_data_type_id, 10) === CONTACT_TYPE_EMAIL);

                contacts.push({
                    id,
                    name,
                    Ref_Key,
                    position,
                    phones,
                    emails,
                });
            }

            return contacts;
        }
    },
    watch: {
        selectedInitiatorContactId() {
            this.selectedInitiatorPhoneId = null;
            this.selectedInitiatorEmailId = null;
        },
    },
    methods: {
         submitForm() {
            this.process = true;
             save1CVO(
                {
                    partnerId: this.contact.partner_id,
                    authorExternalId: this.userExternalId,
                    selectedDestinationContactId: this.contact.id,
                    selectedDestinationPhoneId: this.selectedDestinationPhoneId,
                    selectedDestinationEmailId: this.selectedDestinationEmailId,
                    sendDestinationPhone: this.sendDestinationPhone,
                    sendDestinationEmail: this.sendDestinationEmail,
                    hasWarrantyCoverage: this.hasWarrantyCoverage,
                    selectedInitiatorContactId: this.selectedInitiatorContactId,
                    selectedInitiatorPhoneId: this.selectedInitiatorPhoneId,
                    selectedInitiatorEmailId: this.selectedInitiatorEmailId,
                    technologyParkItemId: this.getTechnologyParkItemId,
                    description: this.getDescription,
                },
                () => {
                    this.success = true;
                    this.process = false;

                    setTimeout(() => {
                        this.closeModal();
                    }, 2000);
                },
                () => {
                    this.process = false;
                }
             );
        },
        closeModal() {
            this.success = false;
            this.showModal = false;
        },
    }
};
</script>

<style src="../styles/contact-info.scss" scoped lang="scss"></style>
